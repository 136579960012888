.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.jumbo {
  background: url(./assets/20200605172559_1-min.jpg) no-repeat fixed bottom;
  background-size: cover;
  color: #ccc;
  height: 450px;
  position: relative;
  z-index: -2;
}

.overlay {
  background-color: #000;
  opacity: 0.2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.bg-light {
  position: relative;
  z-index: -3;
}

.customization-nav-link {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .step-between {
    display: none;
  }
  .step {
    padding: 1rem;
  }
  .table {
    font-size: 9px;
  }
  .characteristics-table .table th, .characteristics-table .table td {
    padding: 0.5rem 0.2rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

